<template>
  <div class="public-course-log">
    <table-list
      title="公开课学习记录"
      :loading="loading"
      :data="courseLogList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '导出记录',
    type: 'primary',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '员工',
    type: 'select',
    prop: 'userId',
    children: _this.studentList.map(item => ({
      value: item.userId,
      label: item.studentName,
    })),
  },
  {
    label: '浏览的课程',
    type: 'select',
    prop: 'courseId',
    children: _this.orgList.map(item => ({
      value: item.courseId,
      label: item.courseName,
    })),
  },
  {
    label: '学习进度',
    type: 'select',
    prop: 'studyRateRange',
    children: [
      { value: 1, label: '0%~25%' },
      { value: 2, label: '26%~50%' },
      { value: 3, label: '51%~75%' },
      { value: 4, label: '76%~99%' },
      { value: 5, label: '100%' },
    ],
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'userName',
    label: '员工名称',
    minWidth: 150,
  },
  {
    prop: 'phone',
    label: '手机号码',
    minWidth: 120,
  },
  {
    prop: 'courseName',
    label: '课程名称',
    minWidth: 120,
  },
  {
    prop: 'studyChapterCount',
    label: '已学章节数',
    minWidth: 100,
    align: 'right',
  },
  {
    prop: 'chapterCount',
    label: '应学章节数',
    minWidth: 140,
    align: 'right',
  },
  {
    prop: 'studyRate',
    label: '学习进度',
    minWidth: 100,
  },
  {
    prop: 'studyScore',
    label: '获取学分',
    minWidth: 100,
  },
]
import TableList from '@/components/TableList'
import {
  publicCourseStudyLog,
  publicCourseLogOrgDropList,
  publicCourseLogStudentDropList,
  publicCourseLogExport,
} from '@/api/course'
import to from 'await-to'
export default {
  name: 'PublicCourseLog',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      loading: false,
      courseLogList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      selectLog: [],
      orgList: [],
      studentList: [],
      queryParams: {},
    }
  },
  mounted() {
    this.getLog()
    this.getOrgList()
    this.getStudentList()
  },
  methods: {
    async getLog() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(publicCourseStudyLog({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseLogList = res.data.list
      this.pager.total = res.data.total
    },
    async getOrgList() {
      const [res, err] = await to(publicCourseLogOrgDropList({}))
      if (err) return this.$message.warning(err.msg)
      this.orgList = res.data
    },
    async getStudentList() {
      const [res, err] = await to(publicCourseLogStudentDropList({}))
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getLog()
    },
    async handleExport() {
      const { current, size } = this.pager
      const [res, err] = await to(
        publicCourseLogExport({
          current,
          size,
          ...this.queryParams,
          ids: this.selectLog.map(item => item.id),
        }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '学习记录'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 多选
    handleSelectionChange(val) {
      this.selectLog = val
      console.log(val)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getLog()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getLog()
    },
  },
}
</script>
